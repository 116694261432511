import logo from './image/logo.jpg';
import repairpicture from './image/repair.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        We have some technical issue , we will be back soon
        </p>
        <img src={repairpicture} className="App-logo" alt="sick" />
      </header>
    </div>
  );
}

export default App;
